/**
 * @file api.js is used for API calls in the React app
 * @author Akshay Bhoite
 * @category File
 * @version 0.1.0
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * POST request to retrieve JIRA issues based on user input.
 * @endpoint "/jira-issues" Endpoint in Express that handles the JIRA API requests.
 * @param projectKey The key of the JIRA project to query.
 * @param issueType Type of the issues to retrieve (e.g., Bug, Task).
 * @param searchString A string included in the issue's summary.
 *
 * @return JSON object containing JIRA issues.
 */
const postJiraIssuesSearch = (id, projectKey, issueType, searchString) => async (dispatch, getState) => {

    dispatch({ type: actions.POST_JIRA_ISSUES_SEARCH_REQUEST })

    const config = {
        params: {
            customerId: id
        }
    }

    try {
        const response = await axios.post(
            '/jira/issues',  // Make sure this matches the route in your Express app
            { projectKey, issueType, searchString },config
        )
        dispatch({ type: actions.POST_JIRA_ISSUES_SEARCH_SUCCESS, payload: response.data })
    } catch (error) {
        dispatch({ type: actions.POST_JIRA_ISSUES_SEARCH_FAILURE, error })
    }
}

export default postJiraIssuesSearch;
