/**
 * getSubCancelMsg Api is used to GET Sub Cancel Msg from Wordpress
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET SubCancelMsg details from WooCommerce REST API.
 * @endpoint "/getSubCancelMsg"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Get Sub Cancel Msg.
 */
const getSubCancelMsg = (id) => async (dispatch, getState) => {
    dispatch({ type: actions.GET_SUB_CANCELLATION_MSG_REQUEST });

    try {
        const response = await axios.get(
            '/jira/getSubCancelMsg',
            {
                params: { customer: id },
                withCredentials: true, // Ensures cookies are included
                validateStatus: (status) => {
                    return status >= 200 && status < 500; // Resolve only if the status code is less than 500
                }
            }
        );

        dispatch({ type: actions.GET_SUB_CANCELLATION_MSG_SUCCESS, payload: response.data });
    } catch (error) {
        console.error('Error fetching subscription cancellation message:', error.response ? error.response.data : error.message);
        dispatch({ type: actions.GET_SUB_CANCELLATION_MSG_FAILURE, error });
    }
};

export default getSubCancelMsg;

