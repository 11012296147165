import React from 'react';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import CustomModal from "../paymentMethods/components/CustomModal";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const StyledArrowDropDownCircleIcon = styled(ArrowDropDownCircleIcon)({
    color: '#0d2959',
    marginRight: '8px',
    cursor: 'pointer',
    fontSize: '1.5em !important',
    "&:hover": {
        color: "#ebe9eb",
        backgroundColor: "#0d2959",
    },
    // Add other styles like transform here if they are constant
});

export const companyTypes = [
    { value: 'Limited Liability Company (LLC)', label: 'Limited Liability Company (LLC)' },
    { value: 'Profit Corporation', label: 'Profit Corporation' },
    { value: 'Nonprofit Corporation', label: 'Nonprofit Corporation' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Revocable Trust', label: 'Revocable Trust' },
    { value: 'Irrevocable Trust', label: 'Irrevocable Trust' },
    { value: 'Other', label: 'Other' },
];

export const IDTypes = [
    { value: 'State issued driver license', label: 'State issued drivers license' },
    { value: 'State/local/tribe-issued ID', label: 'State/local/tribe-issued ID' },
    { value: 'U.S. passport', label: 'U.S. passport' },
    { value: 'Foreign passport', label: 'Foreign passport' },
];

export const AddCompanySchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    company_type: Yup.string().required('Required'),
    custom_company_type: Yup.string().when('company_type', {
        is: 'Other',
        then: Yup.string().required('Required'),
    }),
    state_of_domestication: Yup.string().required('Required'),
    reporting_company_id: Yup.string().length(14, 'Must be exactly 12 digits').nullable().notRequired(),
    company_applicant_id_1: Yup.string().length(14, 'Must be exactly 12 digits').nullable().notRequired(),
    company_applicant_id_2: Yup.string().length(14, 'Must be exactly 12 digits').nullable().notRequired(),
    address_type: Yup.string().required('Required'),
    base_address: Yup.object().when('address_type', {
        is: 'physical',
        then: Yup.object().shape({
            address_1: Yup.string().required('Required'),
            address_2: Yup.string(),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zip_code: Yup.string().required('Required').matches(/^[0-9]*$/, "Must be digits").max(6, 'Max 6 digits'),
            country: Yup.string().required('Required'),
        })
    }),
    mailing_address_same_as_address: Yup.boolean(),
    mailing_address: Yup.object().when("mailing_address_same_as_address", {
        is: false,
        then: Yup.object().shape({
            address_1: Yup.string().required('Required'),
            address_2: Yup.string(),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            zip_code: Yup.string().required('Required').matches(/^[0-9]*$/, "Must be digits").max(6, 'Max 6 digits'),
            country: Yup.string().required('Required'),
        })
    }),
    beneficial_owners: Yup.array().of(
        Yup.object().shape({
            first_name: Yup.string().required('Required'),
            last_name: Yup.string().required('Required'),
            address: Yup.object().shape({
                address_1: Yup.string().required('Required'),
                address_2: Yup.string(),
                city: Yup.string().required('Required'),
                state: Yup.string().required('Required'),
                zip_code: Yup.string().required('Required').matches(/^[0-9]*$/, "Must be digits").max(6, 'Max 6 digits'),
                country: Yup.string().required('Required'),
            }),
            mailing_address_same_as_address: Yup.boolean(),
            mailing_address: Yup.object().when("mailing_address_same_as_address", {
                is: false,
                then: Yup.object().shape({
                    address_1: Yup.string().required('Required'),
                    address_2: Yup.string(),
                    city: Yup.string().required('Required'),
                    state: Yup.string().required('Required'),
                    zip_code: Yup.string().required('Required').matches(/^[0-9]*$/, "Must be digits").max(6, 'Max 6 digits'),
                    country: Yup.string().required('Required'),
                })
            }),
            email_id: Yup.string().email('Invalid email').required('Required'),
            phone_number: Yup.string().required('Required').matches(/^\+?\d{0,12}$/, "Must be digits, max 11 characters"),
            percentage_owned: Yup.string(),
            last_4_ssn_or_itin: Yup.string().matches(/^[0-9]+$/, " must be only digits").length(4, 'Must be exactly 4 digits').nullable().notRequired(),
            additional_info: Yup.string(),
            type_of_boi_id: Yup.string(),
            is_beneficial_owner: Yup.boolean().required('Required')
        })
    )
});

export const initialOwner = {
    first_name: '',
    last_name: '',
    base_address: {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: ''
    },
    mailing_address_same_as_address: true,
    mailing_address: {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zip_code: '',
        country: ''
    },
    email_id: '',
    phone_number: '',
    percentage_owned: '',
    date_of_ownership: '',
    last_4_ssn_or_itin: '',
    additional_info:'',
    expiry_date_of_boi_id: '',
    type_of_boi_id: '',
    is_beneficial_owner: true
};

export const removeOwner = (remove,index)=>{
    return (
        <div>
            <CustomModal
                triggerButtonText="Remove Owner"
                questionText="Are you sure you want to delete this Owner Info?"
                confirmButtonText="Yes"
                cancelButtonText="Cancel"
                onConfirm={() => {
                    remove(index);
                }}
                iconButton={true}
            />
        </div>
    )
}

export const toggleOwnerVisibility = (ownersVisibility, setOwnersVisibility, index) => {
    setOwnersVisibility(prev => ({
        ...prev,
        [index]: !prev[index]
    }));
};

export const handleAddOwner = (push, ownersVisibility, setOwnersVisibility) => {
    push(initialOwner); // Add a new owner

    const newVisibility = Object.keys(ownersVisibility).reduce((acc, key) => {
        acc[key] = false; // Collapse all existing sections
        return acc;
    }, {});

    newVisibility[Object.keys(newVisibility).length] = true; // Open the new section
    setOwnersVisibility(newVisibility);
};

export const handleChange = (setFieldValue, showCustomTypeField, setShowCustomTypeField, value, fieldName, customFieldName) => {
    setFieldValue(fieldName, value);

    if (value === 'Other') {
        setShowCustomTypeField(true);
    } else {
        setShowCustomTypeField(false);
        // Reset custom field if it exists
        if (customFieldName) {
            setFieldValue(customFieldName, '');
        }
    }
};

