import "./orderlist.css";
// import "../../styles/style.scss";
import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {Link} from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";
import {Divider} from "@mui/material";
import setExpandedOrders from "../../actions/setExpandedOrders";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import formatCurrency from "../../functions/formatCurrency";
import {ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";
import {  createTheme } from "@mui/material/styles";
import parse from 'html-react-parser';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import InventoryIcon from '@mui/icons-material/Inventory';
import PendingIcon from '@mui/icons-material/Pending';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import { relatedSubscriptions } from "./ordersUtils";

export const Chip = styled(Box)(({ theme, backgroundColor }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: "6px 15px",
    borderRadius: '20px', // Change as needed
    color: "white",
    backgroundColor: backgroundColor,
    '& .MuiTypography-root': {
        marginRight: theme.spacing(1), // Adjust spacing as needed
        fontWeight: "600 !important",
        letterSpacing: "0.0938em !important",
    },
}));

export const SubChip = styled(Chip)({
    padding: '2px 15px',
    '& .MuiTypography-root': {
        fontSize: '0.8em',
    },
});

export const statusColorMap = {
    ACTIVE: '#c6e1c6',
    PENDING: '#ffd966',
    OABANNED: '#dd3333',
    INACTIVE: '#dd3333',
    PROCESSING: '#c6e1c6',
    INPROGRESS: '#6d98bf',
    COMPLETED: '#c8d7e1',
    ONHOLD: '#f8dda7',
    CANCELLED: '#e5e5e5',
    REFUNDED: '#e5e5e5',
    FAILED: '#eba3a3',
    EXPIRED: '#bd94ae'
};

export const getStatusColor = (status) => {
    return statusColorMap[status] || 'black'; // return black as default color if status not in map
};

export const statusIconMap = {
    ACTIVE: VerifiedIcon,
    PENDING: AccessTimeFilledIcon,
    OABANNED: NotInterestedIcon,
    INACTIVE: InventoryIcon,
    PROCESSING: PendingIcon,
    INPROGRESS: HourglassTopIcon,
    COMPLETED: CheckCircleIcon,
    ONHOLD: RemoveCircleIcon,
    CANCELLED: CancelIcon,
    REFUNDED: ChangeCircleIcon,
    FAILED: ErrorIcon,
    EXPIRED: HighlightOffIcon
}

export const getStatusIcon = (status) => {
    return statusIconMap[status] || AcUnitIcon;
}

export function MyChip({ label , icon: Icon, color }) {
    return (
        <Chip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </Chip>
    );
}

export function MyExpansionChip({ label , icon: Icon, color }) {
    return (
        <SubChip backgroundColor={color} style={{width: 'fit-content'}}>
            <Typography className="MuiTypography-root">{label}</Typography>
            <Icon />
        </SubChip>
    );
}

export const relatedBillingDetails = (first_name,last_name,company,address_1,address_2,city,state,postcode,email,phone) => {
    return (
        <div className={"orderMetaData"}>
            <div className={"serviceName"}>
                <Typography variant="h5" style={{fontWeight: 600}}>Billing Details</Typography><br/>
            </div>
            <div className={"serviceMetaData"}>
                <ul className={"billingDetailsMeta"}>
                    <li>
                        <span><strong>Name</strong></span>
                        <br/>
                        <span>{first_name}{" "}{last_name}</span>
                    </li>
                    <li>
                        <span><strong>Company</strong></span>
                        <br/>
                        <span>{company}</span>
                    </li>
                    <li>
                        <span><strong>Email</strong></span>
                        <br/>
                        <span>{email}</span>
                    </li>
                    <li>
                        <span><strong>Phone</strong></span>
                        <br/>
                        <span>{phone}</span>
                    </li>
                    <li>
                        <span><strong>Address</strong></span>
                        <br/>
                        <span>
                            {address_1}<br/>
                            {address_2}<br/>
                            {city},{" "}{state}{" "}{postcode}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});


function OrdersList(props) {
    const orderList = props.orders;
    const subList = props.subList;

    const [rows, setRows] = useState([]);

    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('none');

    useEffect(() => {
        const storedExpandedRows = sessionStorage.getItem("expandedOrderRows");

        if (storedExpandedRows) {
            setRows(JSON.parse(storedExpandedRows));
        }
    }, []);

    const getFixedWidthProps = (width) => {
        return () => ({
            style: { width, maxWidth: width, minWidth: width }
        });
    };

    const data = orderList && orderList.map((item) => {
        const {id,date_created, line_items, billing: {company},payment_url,needs_payment} = item;
        const date = new Date(date_created.toString()).toLocaleDateString();
        const itemTotal = formatCurrency(item.total);
        let product = line_items.map((e) => e.name).join("\n");
        const paymentURL = () => {

            const payOrderCheckout = () => {
                window.location.assign(payment_url);
            }

            return payment_url !== '' && needs_payment ? (
                <div className={"actionButtons"}>
                    <button onClick={payOrderCheckout} className={"wooButtons"}>
                        Pay
                    </button>
                    <Link to={"/view-order/" + id}>
                        <button className={"wooButtons"}>
                            View
                        </button>
                    </Link>
                </div>) : (
                <div className={"actionButtons"}>
                    <Link to={"/view-order/" + id}>
                        <button className={"wooButtons"}>
                            View
                        </button>
                    </Link>
                </div>
            )
        }
        return [item.id, product, date,item.status.replace(/-/g, "").toUpperCase(), itemTotal,paymentURL];
    });

    const uniqueProductNames = Array.from(
        new Set(data.flatMap((row) => row[1].split("\n")))
    );

    const columns = [
        generateSortableColumn("Order ID", 0, { filter: false, sortThirdClickReset: true,sortDescFirst: false, setCellProps: getFixedWidthProps('10.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Service(s)", 1, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: false,
            setCellProps: () => ({
                style: {
                    ...getFixedWidthProps('25.00%').style,
                    textAlign:'left'
                }
            }),
            filterType: "custom",
            filterOptions: {
                names: uniqueProductNames,
                logic: (product, filterVal) => {
                    if (filterVal.length === 0) {
                        return false;
                    }
                    return !filterVal.some((filter) => product.includes(filter));
                },
                display: (filterList, onChange, index, column) => (
                    <FormControl fullWidth>
                        <InputLabel>Service(s)</InputLabel>
                        <Select
                            multiple
                            value={filterList[index] || []}
                            onChange={(event) => {
                                onChange(event.target.value, index, column);
                            }}
                        >
                            {uniqueProductNames.map((name) => (
                                <MenuItem key={name} value={name}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ),
            },
            customBodyRender: (value) => (
                value.split("\n").map((item, index) => (
                    <div key={index}>
                        {item}
                        {index !== value.split("\n").length - 1 && <br />}
                    </div>
                ))
            ),
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Date", 2, {
            filter: false,
            sortThirdClickReset: true,
            align: "center",
            sortDescFirst: false,
            setCellProps: getFixedWidthProps('15.00%'),
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Status", 3, {
            filter: false,
            sortThirdClickReset: true,
            align: "center",
            sortDescFirst: false,
            setCellProps: getFixedWidthProps('18.00%'),
            customBodyRender: (value) => {
                const color = getStatusColor(value);
                const icon = getStatusIcon(value);
                const displayValue = value === "INPROGRESS" ? "IN-PROGRESS" : value;
                return (
                    <span className={"statusIcon"}>
                    <MyChip label={displayValue} icon={icon} color={color}/>
                </span>
                );
            },
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Total", 4, {
            filter: false,
            align: "center",
            sortThirdClickReset: true,
            sortDescFirst: false,
            setCellProps: getFixedWidthProps('12.00%'),
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Actions", 5, {
            filter: false,
            align: "center",
            setCellProps: getFixedWidthProps('20.00%'),
        }, false, sortedColumn, setSortedColumn, sortDirection, setSortDirection)
    ];

    const options = {
        filterType: "dropdown",
        selectableRows: false,
        selectableRowsOnClick: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: rows,
        rowsPerPageOptions: [10, 15, 20 , 25, 30],
        onTableInit: () => {
            const storedExpandedRows = sessionStorage.getItem("expandedOrderRows");
            if (storedExpandedRows) {
                setRows(JSON.parse(storedExpandedRows));
            }
        },
        setRowProps: (row, dataIndex, rowIndex) => {
            if (rowIndex % 2 === 0) {
                return {
                    style: {
                        backgroundColor: 'white',
                        fontSize: '18px'
                    },
                }
            } else {
                return {
                    style: {
                        backgroundColor: '#f0f0f1',
                        fontSize: '18px'
                    }
                }
            }
        },
        renderExpandableRow: (rowData) => {
            const colSpan = rowData.length + 1;

            return orderList && orderList.map(item => {
                const {
                    id, status, date_created, payment_method_title, line_items,username,relatedSubscriptions:subs,
                    billing: {company, first_name, last_name, address_1, address_2, phone, email, state, city, postcode}
                } = item;
                const dateForOrder = new Date(date_created.toString()).toLocaleDateString();
                const parentExist = subList.map((item) => {
                    const {parent_id} = item;
                    let parent = false;
                    if (parent_id === id) {
                        parent = true;
                    }
                    return parent;
                })

                return (rowData[0] === id) ? (
                        <TableRow>
                            <TableCell colSpan={colSpan}>
                                <div className={"paperAddress"}>
                                    <div className={"order-sub-details-address"}>
                                        {line_items.map((i) => {
                                            const {name, meta_data} = i;

                                            return (
                                                <div className={"orderMetaData"}>
                                                    <div className={"serviceName"}>
                                                        <Link to={"/view-order/" + id} style={{color: '#536889'}}>
                                                            <Typography variant="h5" className={"idNamelink"}>{id}-{name}</Typography>
                                                        </Link>
                                                    </div>
                                                    <div className={"serviceMetaData"}>
                                                        <ul className={"metaDataList"}>
                                                            {meta_data.filter(({ key }) => key.charAt(0) !== "_").slice(0,5).map((c)=>{
                                                                let {key,display_key, value} = c;

                                                                return key.charAt(0) !== "_" ? (
                                                                    <li className={"metadataTitle"}>
                                                                        <span>
                                                                            <strong>{display_key}</strong>
                                                                            <br/>
                                                                            {parse(value)}
                                                                            {/*<p><br/></p>*/}
                                                                        </span>
                                                                    </li>
                                                                ) : (
                                                                    <div hidden="hidden">
                                                                        Sorry
                                                                    </div>
                                                                )
                                                            })}

                                                            {[...Array(Math.max(0, 5 - meta_data.filter(({ key }) => key.charAt(0) !== "_").length))].map((_, i) => (
                                                                <li className={"metadataTitle"} key={`placeholder-${i}`}>
                                                                    <span>&nbsp;</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {relatedSubscriptions(subList, subs)}
                                        {relatedBillingDetails(first_name,last_name,company,
                                            address_1,address_2,city,state,postcode,email,phone)}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                    :
                    (
                        <div hidden="hidden">
                            <TableRow>
                                <TableCell colSpan={colSpan}>

                                </TableCell>
                            </TableRow>
                        </div>
                    )
            })
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            const expandedRowIndexes = allExpanded.map(row => row.dataIndex);
            sessionStorage.setItem('expandedOrderRows', JSON.stringify(expandedRowIndexes));
        },
    };

    return (
        <div className="pageView">
            <div className={"pageWrapper"}>
                <Typography variant="h4" className="pageTitle">My Orders List</Typography>
                {/*<h1 className={"pageTitle"}>My Orders List</h1>*/}
                <CacheProvider value={muiCache}>
                    {/*<ThemeProvider theme={theme}>*/}
                        <MUIDataTable
                            className="customTableHeader"
                            title={""}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    {/*</ThemeProvider>*/}
                </CacheProvider>
            </div>
        </div>
    );
}

export default OrdersList;
