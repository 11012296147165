import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { ArrowBack } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { Link } from "react-router-dom";
import createCache from "@emotion/cache";

function AddSupportRequests(props) {

    const navigate = useNavigate();

    useEffect(() => {

    }, [props]);

    return (
        <div className="pageView">
            <div className="pageWrapper">
                <Typography variant="h4" className={"pageTitle"}>
                    <div>
                        <button className={"backButton"} onClick={() => navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Add Support Request
                </Typography>

            </div>
        </div>
    );
}

export default AddSupportRequests;
