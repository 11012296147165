/**
 * Reducers for Redux-thunk Middleware imported from each reducer.
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import { combineReducers } from 'redux'
import userDetailsState from './userDetailsState'
import ordersState from './orderState'
import subscriptionsState from './subscriptionsState'
import paymentMethodsState from './paymentMethodsState'
import userMetaState from './userMetaState'
import productsState from './productsState'
import jiraIssuesState from "./jiraIssuesState"
// import userLogin from "./userLogin";
import { composeWithDevTools } from "@redux-devtools/extension"
import { legacy_createStore as createStore , applyMiddleware} from "redux";
import {persistStore,persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import thunk from "redux-thunk";
import {reducer as toastrReducer} from 'react-redux-toastr';

const reducers = combineReducers({
  paymentMethodsState,
  userDetailsState,
  ordersState,
  subscriptionsState,
  userMetaState,
  productsState,
  jiraIssuesState,
  // userLogin,
  // toastr: toastrReducer
})

const persistConfig = {
  key: 'main-root',
  storage,
}

const persistedReducer = persistReducer(persistConfig,reducers);

/**
 * Custom Redux Store for our app to apply middlewares, reducers & actions, to access centrally in the app.
 * @link https://redux.js.org/api/store Redux Store Documentation.
 * @const store Redux store for state management.
 * @param reducers Imported reducers from Reducers.
 */
const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

const Persistor = persistStore(store);

export {Persistor};
export default store;
