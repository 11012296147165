import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ConfirmationModal({ isOpen, onClose, acknoledgementMsg }) {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
        >
            <Box sx={style}>
                <Typography id="confirmation-modal-title" variant="h6" component="h2">
                    Request Submitted
                </Typography>
                <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
                    { acknoledgementMsg }
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                    <button onClick={onClose} className={"wooButtons"} type="button">
                        Acknowledge
                    </button>
                </div>
            </Box>
        </Modal>
    );
}
