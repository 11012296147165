import * as actions from '../actions/actionTypes';

const initState = {
    issues: [],
    loading: false,
    error: null,
    total: 0,
    startAt: 0,
    maxResults: 0,
    issueAdded: false,
    CancelSubs: [],
    SubCancellationMsg: ""
};

const jiraIssuesState = (state = initState, action) => {
    switch (action.type) {
        case actions.POST_JIRA_ISSUES_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case actions.POST_JIRA_ISSUES_SEARCH_SUCCESS:
            const subscriptionIDsSearch = Array.isArray(action.payload.issues) ?
                action.payload.issues.map(issue => {
                    const summary = issue.fields?.summary;
                    const idMatch = summary ? summary.match(/Subscription ID: (\d+),/) : null;
                    return idMatch ? idMatch[1] : null;
                }).filter(id => id !== null) : [];

            return {
                ...state,
                loading: false,
                issues: Array.isArray(action.payload.issues) ? action.payload.issues : [],
                total: action.payload.total || state.total,
                startAt: action.payload.startAt || state.startAt,
                maxResults: action.payload.maxResults || state.maxResults,
                CancelSubs: subscriptionIDsSearch
            };

        case actions.POST_JIRA_ISSUES_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actions.POST_JIRA_ISSUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                issueAdded: false // Reset issueAdded flag when a new request is made
            };

        case actions.POST_JIRA_ISSUE_SUCCESS:
            const newIssue = action.payload.createdIssue;
            const updatedIssues = Array.isArray(action.payload.issues?.issues) ? action.payload.issues.issues : [];
            const newSubscriptionIDs = updatedIssues.map(issue => {
                const summary = issue.fields?.summary;
                const idMatch = summary ? summary.match(/Subscription ID: (\d+),/) : null;
                return idMatch ? idMatch[1] : null;
            }).filter(id => id !== null);

            return {
                ...state,
                loading: false,
                issues: updatedIssues,
                total: action.payload.issues.total || state.total,
                startAt: action.payload.issues.startAt || state.startAt,
                maxResults: action.payload.issues.maxResults || state.maxResults,
                issueAdded: true,
                CancelSubs: [...state.CancelSubs, ...newSubscriptionIDs]
            };

        case actions.POST_JIRA_ISSUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case actions.GET_SUB_CANCELLATION_MSG_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }
        case actions.GET_SUB_CANCELLATION_MSG_SUCCESS:
            return {
                ...state,
                loading: false,
                SubCancellationMsg: action.payload
            }
        case actions.GET_SUB_CANCELLATION_MSG_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actions.RESET_ISSUE_ADDED:
            return {
                ...state,
                issueAdded: false // Reset issueAdded to false
            };
        default:
            return state;
    }
};

export default jiraIssuesState;
