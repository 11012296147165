import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const StyledDeleteForeverIcon = styled(IconButton)({
    color: '#0d2959',
    cursor: 'pointer',
    fontSize: '1.5em !important',
    "&:hover": {
        color: "#ebe9eb",
        backgroundColor: "#0d2959",
    },
});

const JiraCancelSub = ({ open, handleClose, handleSubmit, subscriptionId, userEmail, userId, usersName }) => {
    const [description, setDescription] = useState('');

    const onSubmit = () => {
        const summary = `Subscription ID: ${subscriptionId}, for ${userEmail}-${userId}`;
        handleSubmit({ subscriptionId, userEmail, userId, usersName, summary, description });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Cancel Subscription
                </Typography>
                <TextField
                    label="Summary"
                    value={`Subscription ID: ${subscriptionId}, for ${userEmail}-${userId}`}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Name"
                    value={usersName}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Email"
                    value={userEmail}
                    InputProps={{ readOnly: true }}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                />
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <button onClick={onSubmit} className="wooButtons" type="button">
                        Submit
                    </button>
                    <button onClick={handleClose} className="wooButtons" type="button">
                        Cancel
                    </button>
                </Box>
            </Box>
        </Modal>
    );
};

export default JiraCancelSub;
