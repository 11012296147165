import "./sidebar.css";
import React, {useEffect, useState, useCallback} from "react";
import {useDispatch} from "react-redux";
import { NavLink} from "react-router-dom";
import { Divider} from "@mui/material";
import {LineStyle, Business, Subscriptions, Store, Receipt, PersonOutline,Help, ExitToApp, Domain} from "@mui/icons-material";
import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';
import PaymentIcon from '@mui/icons-material/Payment';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Typography } from '@mui/material';

export default function Sidebar(props) {

    const dispatch = useDispatch();
    const { logout } = useAuth0();

    const userId = props.id;
    const wp_site_url = process.env.REACT_APP_WP_SITE_URL;
    const logoutUrl = wp_site_url + '/logout';

    const [isFullSidebar, setIsFullSidebar] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1050);

    const toggleSidebarSize = () => {
        setIsFullSidebar(!isFullSidebar);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1050);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    const renderSidebar = () => {
        if (isMobileView || !isFullSidebar) {
            // Mobile view (icons-only sidebar)
            return (
                <div className={"sidebarHolder"}>
                    <div className={"sidebarWrapperHolder"}>
                        <Tooltip title="Show Sidebar" placement="top">
                            <button className={"toggleSidebar"} onClick={toggleSidebarSize}>
                                <ArrowForwardSharpIcon/>
                            </button>
                        </Tooltip>
                        <div className={"sidebarMenu"}>
                            <ul className={"sidebarList"}>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Home" placement="top">
                                        <NavLink to="/" className="navLinkStyle">
                                            <HomeIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Orders List" placement="top">
                                        <NavLink to="/orders" className="navLinkStyle">
                                            <GradingIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Subscriptions List" placement="top">
                                        <NavLink to="/subscriptions" className="navLinkStyle">
                                            <Subscriptions className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Payment Methods" placement="top">
                                        <NavLink to="/payment-methods" className="navLinkStyle">
                                            <PaymentIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Companies" placement="top">
                                        <NavLink to="/companies" className="navLinkStyle">
                                            <AddBusinessIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Support Requests" placement="top">
                                        <NavLink to="/admin-requests" className="navLinkStyle">
                                            <SupervisorAccountIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                        <div className={"sidebarMenu"}>
                            <ul className={"sidebarList"}>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Profile" placement="top">
                                        <NavLink to="/profile" className="navLinkStyle">
                                            <PersonOutline className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Edit Address" placement="top">
                                        <NavLink to="/edit-address" className="navLinkStyle">
                                            <ContactPageIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Help Page" placement="top">
                                        <NavLink to="/help" className="navLinkStyle">
                                            <HelpCenterIcon className={"sidebarIcon"}/>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to L4SB" placement="top">
                                        <a href={wp_site_url} className="navLinkStyle">
                                            <Domain className={"sidebarIcon"}/>
                                        </a>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Logout L4SB" placement="top">
                                        <a href={logoutUrl} className="navLinkStyle">
                                            <LogoutIcon className={"sidebarIcon"}/>
                                        </a>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        } else {
            // Desktop view (full sidebar)
            return (
                <div className={"sidebar"}>
                    <div className={"sidebarWrapper"}>
                        <Tooltip title="Hide Sidebar" placement="top">
                            <button className={"toggleSidebar"} onClick={toggleSidebarSize}>
                                <ArrowBackSharpIcon/>
                            </button>
                        </Tooltip>
                        <div className={"sidebarMenu"}>
                            <Typography
                                variant="h4"
                                style={{color: '#0d2959',letterSpacing: '2px',fontSize:'1rem'}}
                            >
                                DASHBOARD
                            </Typography>
                            <ul className={"sidebarList"}>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Home" placement="top">
                                        <NavLink to="/" className="navLinkStyle">
                                            <HomeIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Home</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Orders List" placement="top">
                                        <NavLink to="/orders" className="navLinkStyle">
                                            <GradingIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>My Orders</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Subscriptions List" placement="top">
                                        <NavLink to="/subscriptions" className="navLinkStyle">
                                            <Subscriptions className={"sidebarIcon"}/>
                                            <span className={"listItem"}>My Subscriptions</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Payment Methods" placement="top">
                                        <NavLink to="/payment-methods" className="navLinkStyle">
                                            <PaymentIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Payment Methods</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Companies" placement="top">
                                        <NavLink to="/companies" className="navLinkStyle">
                                            <AddBusinessIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Companies</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Support Requests" placement="top">
                                        <NavLink to="/admin-requests" className="navLinkStyle">
                                            <SupervisorAccountIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Support Requests</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                        <div className={"sidebarMenu"}>
                            <Typography
                                variant="h4"
                                style={{color: '#0d2959',letterSpacing: '2px',fontSize:'1rem'}}
                            >
                                QUICK MENU
                            </Typography>
                            <ul className={"sidebarList"}>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to Profile" placement="top">
                                        <NavLink to="/profile" className="navLinkStyle">
                                            <PersonOutline className={"sidebarIcon"}/>
                                            <span className={"listItem"}>My Profile</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Edit Address" placement="top">
                                        <NavLink to="/edit-address" className="navLinkStyle">
                                            <ContactPageIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Address</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Help Page" placement="top">
                                        <NavLink to="/help" className="navLinkStyle">
                                            <HelpCenterIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Need Help?</span>
                                        </NavLink>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Go to L4SB" placement="top">
                                        <a href={wp_site_url} className="navLinkStyle">
                                            <Domain className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Go To L4SB.com</span>
                                        </a>
                                    </Tooltip>
                                </li>
                                <li className={"sidebarListItem"}>
                                    <Tooltip title="Logout L4SB" placement="top">
                                        <a href={logoutUrl} className="navLinkStyle">
                                            <LogoutIcon className={"sidebarIcon"}/>
                                            <span className={"listItem"}>Logout</span>
                                        </a>
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="versionNumber">
                        <span>v2.1.8</span>
                    </div>
                </div>
            );
        }
    };

    const logoutButton = () => {
        sessionStorage.removeItem('switchToQueryParams');
        window.location.replace(wp_site_url+'/logout');
    }

    return renderSidebar();
}